var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    { ref: "unitsRef", staticClass: "units", attrs: { color: "#ffffff" } },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _c(
                "v-slide-group",
                {
                  staticClass: "py-2",
                  attrs: { "center-active": "" },
                  model: {
                    value: _vm.localSlideGroupModel,
                    callback: function ($$v) {
                      _vm.localSlideGroupModel = $$v
                    },
                    expression: "localSlideGroupModel",
                  },
                },
                [
                  _c(
                    "v-slide-item",
                    { key: 0 },
                    [
                      _c("v-autocomplete", {
                        staticClass: "ml-2 pill-mobile-filters",
                        staticStyle: {
                          "max-width": "200px",
                          "font-size": "12px",
                        },
                        attrs: {
                          rounded: "",
                          dense: "",
                          flat: "",
                          solo: "",
                          outlined: "",
                          "hide-details": "auto",
                          items: _vm.classesAndBanks,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  typeof data.item.value === "string" &&
                                  data.item.value.startsWith("separator")
                                    ? _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-divider", {
                                                staticClass: "mt-5",
                                              }),
                                              _c(
                                                "v-subheader",
                                                {
                                                  staticClass: "justify-center",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.item.text) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("v-divider", {
                                                staticClass: "mb-5",
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("v-list-item-content", [
                                        _vm._v(
                                          " " + _vm._s(data.item.text) + " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          950598875
                        ),
                        model: {
                          value: _vm.currentSubjectId,
                          callback: function ($$v) {
                            _vm.currentSubjectId = $$v
                          },
                          expression: "currentSubjectId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0",
                  class: { "mt-5": _vm.$vuetify.breakpoint.mdAndUp },
                  attrs: { flat: "" },
                },
                [
                  _c("v-data-table", {
                    ref: "table",
                    attrs: {
                      "item-class": _vm.getItemClass,
                      page: _vm.page,
                      "items-per-page": _vm.itemsPerPage,
                      "footer-props": _vm.footerProps,
                      "multi-sort": false,
                      "show-select": _vm.editMode,
                      loading: _vm.localListLoading,
                      "loading-text": _vm.$t("listLoadingMsg"),
                      headers: _vm.filteredTableHeaders,
                      items: _vm.localUnits,
                      "item-key": "unitId",
                      height: _vm.tableHeight,
                      search: _vm.searchText,
                      "fixed-header": "",
                      "hide-default-footer": "",
                      "disable-pagination": "",
                      "mobile-breakpoint": _vm.mobileBreakpoint,
                    },
                    on: {
                      "update:page": function ($event) {
                        _vm.page = $event
                      },
                      "update:itemsPerPage": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "update:items-per-page": function ($event) {
                        _vm.itemsPerPage = $event
                      },
                      "click:row": _vm.editUnit,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function ({ items, isSelected, select }) {
                          return [
                            _c(
                              "tbody",
                              {
                                ref: "listContainer",
                                staticClass: "list-container",
                              },
                              _vm._l(items, function (item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class:
                                      _vm.getItemClass(item) +
                                      " " +
                                      _vm.mobileTableClass,
                                    attrs: { tabindex: "0", role: "row" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editUnit(item)
                                      },
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editUnit(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm.editMode
                                      ? _c(
                                          "td",
                                          {
                                            class: _vm.mobileRowClass,
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                select(item, !isSelected(item))
                                              },
                                            },
                                          },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass,
                                                })
                                              : _vm._e(),
                                            _c("v-checkbox", {
                                              class: _vm.mobileCellClass,
                                              staticStyle: {
                                                margin: "0px",
                                                padding: "0px",
                                              },
                                              attrs: {
                                                "input-value": isSelected(item),
                                                color: !_vm.$vuetify.theme.dark
                                                  ? "#7a7a7a"
                                                  : "#ffffff",
                                                "hide-details": "",
                                                "aria-label": item.unitTitle,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.isSelectedSubjectFromBank
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.mobileHeaderClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.tableHeaders[0].text
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.isNotaUnitBank(item)
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: _vm.mobileCellClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.stringifyDate(
                                                          item.unitStart,
                                                          true
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.isSelectedSubjectFromBank
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class:
                                                      _vm.mobileHeaderClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.tableHeaders[1].text
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.isNotaUnitBank(item)
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: _vm.mobileCellClass,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.stringifyDate(
                                                          item.unitEnd,
                                                          true
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.tableHeaders[2].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "d-flex justify-left",
                                          class: _vm.mobileCellClass,
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-inline-block pr-2",
                                            },
                                            [
                                              _vm.contentBreakpoint === "sm"
                                                ? _c(
                                                    "pb-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        label: item.className,
                                                        color: _vm.getColor(
                                                          item.unitColor ||
                                                            item.color
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "fa fa-puzzle-piece"
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "pb-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: _vm.getColor(
                                                          item.unitColor ||
                                                            item.color
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "fa fa-puzzle-piece"
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                          _vm.contentBreakpoint !== "sm"
                                            ? _c("span", [
                                                _vm._v(_vm._s(item.className)),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.tableHeaders[3].text)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.editUnitNum
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  autofocus: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                  "append-icon": "fa fa-check",
                                                  value: item.unitNum,
                                                  disabled:
                                                    _vm.localListLoading,
                                                },
                                                on: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    return _vm.saveUnitNum(item)
                                                  },
                                                  input: function ($event) {
                                                    return _vm.updateUnitNum(
                                                      item,
                                                      $event
                                                    )
                                                  },
                                                  "click:append": function (
                                                    $event
                                                  ) {
                                                    return _vm.saveUnitNum(item)
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.disableEditable(
                                                      false,
                                                      true
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              class: _vm.mobileCellClass,
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.toggleUnitNumEditable(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.unitNum)),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ml-2 hover-edit-icon",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { "x-small": "" },
                                                    },
                                                    [_vm._v("fal fa-pencil")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                    ]),
                                    _c(
                                      "td",
                                      {
                                        class: _vm.mobileRowClass,
                                        style: {
                                          "min-width": _vm.tableWidths.longText,
                                        },
                                      },
                                      [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.tableHeaders[4].text
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        item.editUnitTitle
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    autofocus: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                    "append-icon":
                                                      "fa fa-check",
                                                    value: item.unitTitle,
                                                    disabled:
                                                      _vm.localListLoading,
                                                  },
                                                  on: {
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      )
                                                        return null
                                                      return _vm.saveUnitTitle(
                                                        item
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      return _vm.updateUnitTitle(
                                                        item,
                                                        $event
                                                      )
                                                    },
                                                    "click:append": function (
                                                      $event
                                                    ) {
                                                      return _vm.saveUnitTitle(
                                                        item
                                                      )
                                                    },
                                                    blur: function ($event) {
                                                      return _vm.disableEditable(
                                                        true,
                                                        false
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                class: _vm.mobileCellClass,
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.toggleUnitTitleEditable(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.unitTitle)
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ml-2 hover-edit-icon",
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                        },
                                                      },
                                                      [_vm._v("fal fa-pencil")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                      ]
                                    ),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c("div", {
                                            class: _vm.mobileHeaderClass,
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mx-3 hover-button-primary",
                                              attrs: {
                                                text: "",
                                                small: "",
                                                color:
                                                  _vm.contentBreakpoint === "xs"
                                                    ? "primary"
                                                    : "transparent",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.editUnit(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("editLabel")) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mx-3 hover-button-error",
                                              attrs: {
                                                text: "",
                                                small: "",
                                                color:
                                                  _vm.contentBreakpoint === "xs"
                                                    ? "error"
                                                    : "transparent",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.deleteUnit(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("deleteLabel")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedRows,
                      callback: function ($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }