
import { emailFormat, tableWidths } from '@/constants';
import UnitsMixin from '@/mixins/units-mixin';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import moment from 'moment-timezone';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../../components/core/Confirm.vue';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';

const settings = namespace('settings');
const units = namespace('units');
const classes = namespace('classes');

@Component({
  mixins: [PageLifeCycleMixin, UnitsMixin, TableResizeMixin]
})
export default class Units extends Vue {
  localUnits: Array<any> = [];
  selectedRows!: Array<any>;
  editMode!: boolean;
  searchText!: string;
  tableHeight!: any;
  page = 1;
  itemsPerPage = 10;
  currentSubjectId = 0;
  localSlideGroupModel = null;

  @settings.Getter('getSettings')
  userInfo!: any;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Getter
  getSharingOptionsSettings!: any;

  @settings.Getter('getSharedClasses')
  sharedClass!: any[];

  @units.Action
  loadUnits!: (params?: any) => Promise<any>;

  @units.Action
  init!: (params?: any) => Promise<any>;

  @units.Action('deleteUnits')
  doDeleteUnits!: (params?: any) => Promise<any>;

  @units.State
  unitId!: number;

  @units.State
  units!: Array<any>;

  @units.Action('save')
  save!: (params?: any) => Promise<any>;

  @units.Getter('getListLoading')
  listLoading!: boolean;

  @classes.Getter('getClassItems')
  classItems!: any[];

  @classes.Getter('getLessonBankItems')
  lessonBankItems!: Array<any>;

  @Watch('editMode')
  updateSelectedRows(v: boolean) {
    if (!v) {
      this.selectedRows = [];
    }
  }

  $refs!: {
    confirm: Confirm,
    importForm: Vue & { validate: () => boolean, resetValidation: () => void }
  }

  get sharedClasses():any[] {
    const sharedClasses:any[] = [];
    this.getSharingOptionsSettings.sharedList.forEach((shared:any) => {
      shared.years.forEach((year:any) => {
        if (year.yearId > 0) {
          year.classes.forEach((classes:any) => {
            classes.text = classes.className;
            sharedClasses.push(classes);
          });
        }
      });
    });
    return [
      {
        value: 'separator2',
        text: this.$t('sharedClassesLabel')
      }].concat(sharedClasses.map((s:any) => {
      s.value = s.classId;
      return s;
    })
      .sort((a:any, b:any) => {
        const fa = a.email.toLowerCase();
        const fb = b.email.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      }));
  }

  get listViewClasses() {
    return this.classItems.concat(this.sharedClasses);
  }

  get localClassItems() {
    return [
      {
        value: 'separator1',
        text: this.$t('classesLabel')
      },
      {
        color: this.$vuetify.theme.currentTheme.primary,
        isGoogleClass: false,
        isReplica: false,
        text: this.$t('allClassesLabel'),
        value: 0,
        yearId: 0
      }].concat(this.classItems.filter((c: any) => !c.isGoogleClass));
  }

  get hasSharedClasses() {
    return CommonUtils.isNotEmpty(this.sharedClasses);
  }

  get hasSharedBanks() {
    return CommonUtils.isNotEmpty(this.sharedBanksClass);
  }

  get localLessonBankItems():any[] {
    return [
      {
        value: 'separator3',
        text: this.$t('lessonBanksLabel')
      },
      {
        text: this.$t('allBanksLabel'),
        value: -1,
        yearId: 0
      }].concat(this.lessonBankItems);
  }

  get sharedBanksClass():any[] {
    const sharedClasses:any = [];
    Object.entries(this.sharedClass).forEach((sharedClass: any) => {
      if (sharedClass[1].yearId === 0) {
        sharedClass[1].text = this.truncate(sharedClass[1].className, { length: 30 });
        sharedClass[1].value = sharedClass[1].classId;
        sharedClasses.push(sharedClass[1]);
      }
    });
    return [
      {
        value: 'separator4',
        text: this.$t('sharedLessonBanksLabel')
      }].concat(sharedClasses);
  }

  get truncate() {
    return ld.truncate;
  }

  get currentSubjectName() {
    if (this.currentSubjectId === 0) return this.$t('allClassesLabel');
    if (this.currentSubjectId === -1) return this.$t('allBanksLabel');
    return (this.localClassItems.find(c => c.value === this.currentSubjectId) || this.sharedClasses.find(c => c.value === this.currentSubjectId) ||
      this.localLessonBankItems.find(b => b.value === this.currentSubjectId) || this.sharedBanksClass.find(b => b.value === this.currentSubjectId)).text;
  }

  get classesAndBanks() {
    if (this.$currentUser.isTeacher) {
      const classes = this.localClassItems.concat(this.sharedClasses);
      const banks = this.localLessonBankItems.concat(this.sharedBanksClass);
      return classes.concat(banks);
    } else {
      return this.localLessonBankItems.concat(this.sharedBanksClass);
    }
  }

  get localRules() {
    return [
      (v: any) => !!v || this.$t('requiredLabel')
    ];
  }

  get emailRules() {
    return this.localRules.concat([
      (v: string) => emailFormat.test(v) || this.$t('emailMustBeValidMsg')
    ]);
  }

  get footerProps() {
    return {
      'items-per-page-options': [10, 25, 50, 100, -1]
    }
  }

  get itemDataSelect() {
    return 'item.data-table-select'
  }

  get filteredTableHeaders() {
    return this.tableHeaders.filter(h => {
      if ((h.value === 'formattedStart' || h.value === 'formattedEnd') && this.isSelectedSubjectFromBank) {
        return false;
      }
      return true;
    });
  }

  get tableHeaders() {
    return [
      {
        width: this.tableWidths.fullDate,
        text: this.$t('startDateLabel'),
        value: 'formattedStart'
      },
      {
        width: this.tableWidths.fullDate,
        text: this.$t('endDateLabel'),
        value: 'formattedEnd'
      },
      {
        width: (this.isSelectedSubjectFromBank && this.contentBreakpoint !== 'sm') ? '' : this.tableWidths.icon,
        text: this.isSelectedSubjectFromBank ? this.$t('bankLabel') : this.$t('classLabel'),
        align: 'start',
        value: 'className'
      },
      {
        width: this.tableWidths.shortText,
        text: this.$t('unitIDLabel'),
        align: 'start',
        value: 'unitNum'
      },
      {
        text: this.$t('unitTitleLabel'),
        align: 'start',
        value: 'unitTitle',
        class: 'header-longText'
      },
      {
        width: this.tableWidths.action2,
        sortable: false,
        value: 'action'
      }
    ]
  }

  get tableWidths() {
    return tableWidths;
  }

  get haveUnits() {
    return this.$store.state.events.haveEvents;
  }

  set haveUnits(value: boolean) {
    this.$store.commit('units/setHaveUnits', value);
  }

  get isCycleSchedule() {
    return !isNaN(+this.userInfo.schoolYearSettings.classCycle);
  }

  get formatToShow() {
    return DateTimeUtils.formatToShow
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get isTrue() {
    return CommonUtils.isTrue;
  }

  get localListLoading() {
    return this.listLoading;
  }

  set localListLoading(value: boolean) {
    this.$store.commit('units/setListLoading', value);
  }

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    console.log(CommonUtils.mainContentBreakpoint())
    return CommonUtils.mainContentBreakpoint();
  }

  get isSelectedSubjectFromBank():boolean {
    return !this.listViewClasses.some(l => +l.value === +this.currentSubjectId) && this.currentSubjectId !== 0;
  }

  isNotaUnitBank(unit:any):boolean {
    return this.listViewClasses.some(l => +l.value === +unit.subjectId);
  }

  toggleUnitNumEditable(item: any) {
    const units = ld.cloneDeep(this.localUnits);
    units.forEach(e => {
      e.editUnitNum = false;
      e.editUnitTitle = false;
    });
    const event = units.find(e => e.unitId === item.unitId);
    event.editUnitNum = !event.editUnitNum;
    this.localUnits = units;
  }

  toggleUnitTitleEditable(item: any) {
    const units = ld.cloneDeep(this.localUnits);
    units.forEach(e => {
      e.editUnitNum = false;
      e.editUnitTitle = false;
    });
    const event = units.find(e => e.unitId === item.unitId);
    event.editUnitTitle = !event.editUnitTitle;
    this.localUnits = units;
  }

  @Watch('localListLoading')
  disableEditable(unitTitle?: boolean, unitNum?: boolean) {
    if (!this.localListLoading) {
      const units = ld.cloneDeep(this.localUnits);
      units.forEach(e => {
        e.editUnitTitle = unitTitle === true || unitTitle === undefined ? false : e.editUnitTitle;
        e.editUnitNum = unitNum === true || unitNum === undefined ? false : e.editUnitNum;
      });
      this.localUnits = units;
    }
  }

  @Watch('units')
  @Watch('currentSubjectId')
  onCurrentSubjectIdChanged() {
    this.localUnits = this.units.map(e => {
      return ld.merge(e, {
        formattedStart: this.formatSortDate(e.unitStart),
        formattedEnd: this.formatSortDate(e.unitEnd),
        editable: true
      });
    });
    if (this.currentSubjectId === 0) {
      const allClassesId = this.localClassItems.concat(this.sharedClasses).map(b => b.value);
      this.localUnits = this.localUnits.filter(u => allClassesId.includes(u.subjectId));
    } else if (this.currentSubjectId === -1) {
      const allBanksId = this.localLessonBankItems.concat(this.sharedBanksClass).map(b => b.value);
      this.localUnits = this.localUnits.filter(u => allBanksId.includes(u.subjectId));
    } else if (this.currentSubjectId !== 0) {
      this.localUnits = this.localUnits.filter(u => u.subjectId === this.currentSubjectId);
    }
  }

  updateUnitTitle(item: any, value: string) {
    const units = ld.cloneDeep(this.localUnits);
    const unit = units.find(e => e.unitId === item.unitId);
    unit.unitTitle = value;
    this.localUnits = units;
  }

  saveUnitTitle(item: any) {
    const units = ld.cloneDeep(this.localUnits);
    const unit = units.find(e => e.unitId === item.unitId);
    const origEvent = this.units.find(e => e.unitId === item.unitId);
    this.localListLoading = true;
    if (item.unitTitle !== origEvent.unitTitle) {
      unit.unitTitle = item.unitTitle;
      units.forEach(e => {
        e.editUnitTitle = false;
        e.editUnitNum = false;
      });
      this.init(item).then(() => {
        return this.save().then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg39') as string);
          }
        });
      }).finally(() => {
        this.localListLoading = false;
      });
    } else {
      this.$nextTick(() => {
        this.localListLoading = false;
      })
    }
  }

  updateUnitNum(item: any, value: string) {
    const units = ld.cloneDeep(this.localUnits);
    const unit = units.find(e => e.unitId === item.unitId);
    unit.unitNum = value;
    this.localUnits = units;
  }

  saveUnitNum(item: any) {
    const units = ld.cloneDeep(this.localUnits);
    const unit = units.find(e => e.unitId === item.unitId);
    const origEvent = this.units.find(e => e.unitId === item.unitId);
    this.localListLoading = true;
    if (item.unitNum !== origEvent.unitNum) {
      unit.unitTitle = item.unitTitle;
      units.forEach(e => {
        e.editUnitTitle = false;
        e.editUnitNum = false;
      });
      this.init(item).then(() => {
        return this.save().then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg39') as string);
          }
        });
      }).finally(() => {
        this.localListLoading = false;
      });
    } else {
      this.$nextTick(() => {
        this.localListLoading = false;
      })
    }
  }

  compareInstances(start: string, end: string) {
    const momentStart = moment(start, 'MM/DD/YYYY', true);
    const momentEnd = moment(end, 'MM/DD/YYYY', true);
    if (momentStart > momentEnd) return 1;
    else if (momentStart < momentEnd) return -1;
    else return 0;
  }

  editUnit(item: any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'unit',
      modal: this.$currentUser.defaultEditorMode === 'modal',
      width: 600,
      input: item
    });
  }

  getColor(color: any) {
    return CommonUtils.getColor(color) || this.$vuetify.theme.currentTheme.primary;
  }

  async deleteUnits() {
    return this.$refs.confirm.confirm({
      title: this.$t('deleteUnitsLabel'),
      text: this.$t('confirmDeleteUnitsMg'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.localListLoading = true;
        return this.doDeleteUnits({
          units: this.selectedRows
        }).then(() => {
          this.$eventBus.$emit('deleteUnit', this.selectedRows.map((u: any) => { return u.unitId }));
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg40') as string);
          }
          this.selectedRows = [];
          return Promise.resolve();
        });
      }
    }).finally(() => {
      this.localListLoading = false;
    });
  }

  async deleteUnit(unit: any) {
    return this.$refs.confirm.confirm({
      title: this.$t('deleteUnitLabel'),
      text: this.$t('confirmDeleteUnit'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.localListLoading = true;
        return this.doDeleteUnits({
          units: [unit]
        }).then(() => {
          this.$eventBus.$emit('deleteUnit', [unit.unitId]);
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg40') as string);
          }
          return Promise.resolve();
        });
      }
    }).finally(() => {
      this.localListLoading = false;
    });
  }

  formatSortDate(date: string) {
    if (CommonUtils.hasText(date)) {
      return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD')
    }
    return '01/01/0001'
  }

  initialize() {
    CommonUtils.showLoading();
    this.haveUnits = false;
    this.loadUnits().then(() => {
      CommonUtils.hideLoading();
    });
  }

  getItemClass(item: any) {
    return 'unit unit' + item.unitId;
  }

  created() {
    this.$nextTick(() => {
      this.$eventBus.$emit('openDefaultSubPage');
    })
    if (this.$currentUser.isAdmin) {
      this.currentSubjectId = -1;
    }
    this.initialize();
  }
}
